<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: index.vue
Description:This file will import the necessay files for the application.This is the element the Vue application will use to attach to the DOM.
-->
<template>
  <el-container
    :class="['container--main', showControl ? 'nav--opened' : 'nav--closed']"
  >
    <el-aside
      class="aside__nav"
      :class="showControl ? 'aside__nav--opened' : 'aside__nav--closed'"
    >
      <navigationMenu @close-nav="showControl = false"></navigationMenu>
    </el-aside>
    <el-container class="container-body">
      <el-header class="header__nav">
        <dashboard-header
          :hideNavButton="isNecessaryInfoFilled"
        ></dashboard-header>
      </el-header>
      <el-container v-if="getAppState === 'NO_NECESSARY_INFO'">
        <necessaryUserDetails />
      </el-container>
      <div class="container-body__main">
        <template v-if="getAppState === 'NO_LOCATIONS'">
          <no-ponds-view></no-ponds-view>
        </template>
        <template v-else-if="getAppState === 'PONDLOGS_APP'">
          <!-- <transition name="fade"> -->
          <router-view :key="getCurrUserLocationId"></router-view>
          <!-- </transition> -->
        </template>
      </div>
    </el-container>
  </el-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import navigationMenu from "@/components/index/navigationMenu";
import dashboardHeader from "@/components/index/header";
import necessaryUserDetails from "@/views/necessaryUserDetails";
import noPondsView from "@/views/noPondsView";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import commonUtils from "@/utils/commonUtils";
import { Notification } from "element-ui";
import hashSum from "hash-sum";
export default {
  mixins: [errorHandlerMixin],
  components: {
    navigationMenu,
    dashboardHeader,
    necessaryUserDetails,
    noPondsView
  },
  data() {
    return {
      loading: false
    };
  },
  watch: {
    getURLLocationId: function(newLocationId) {
      this.setCurrUserLocation(newLocationId);
    }
  },
  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),
    ...mapGetters("user", {
      getUserCountryName: "getUserCountryName",
      getUserProfile: "getUserProfile",
      getCurrUserLocation: "getCurrUserLocation",
      getUserLocationsObj: "getUserLocationsObj",
      isReadOnly: "isReadOnly"
    }),
    ...mapGetters("header", {
      showControl: "getSideNavStatus"
    }),
    getURLLocationId() {
      return this.$route.query.location_id;
    },
    getCurrUserLocationId() {
      return this.getCurrUserLocation._id;
    },
    getAppState() {
      if (!this.isNecessaryInfoFilled) {
        return "NO_NECESSARY_INFO";
      }
      const hasLocations = Object.keys(this.getUserLocationsObj).length;
      if (!hasLocations && this.$route.meta.dependOnLocations) {
        return "NO_LOCATIONS";
      }
      return "PONDLOGS_APP";
    },
    MapRouteNameNavIndex() {
      return {
        dashboard: "Comn_dashboard",
        map: "Comn_map",
        ponds: "Comn_ponds",
        battery: "Comn_battery",
        manualFeeding: "Comn_manual_feeding",
        pondFeeding: "PM_pond_feeding",
        pondMotherFeeding: "Comn_pond_mother",
        checkTray: "Comn_check_tray",
        cultureMedicine: "Comn_culture_medicine",
        abw: "ABW_abw",
        labTests: "LabTst_lab_tests",
        yield: "Comn_yield",
        stockedFeed: "Comn_feed",
        stockedMedicine: "Comn_stock_medicine",
        gateway: "Comn_gateway",
        reports: "Comn_reports",
        resources: "Comn_resources",
        settings: "Comn_settings",
        helpSection: "Comn_help-section"
      };
    },
    isNecessaryInfoFilled() {
      if (!this.isCountryFilled || !this.isTimezoneFilled) {
        return false;
      }
      return true;
    },
    isCountryFilled() {
      if (
        !this.getUserProfile.country ||
        !this.getUserProfile.country.name ||
        !this.getUserProfile.country.code
      ) {
        return false;
      }
      return true;
    },
    isTimezoneFilled() {
      if (
        !this.getUserProfile.timezone ||
        !this.getUserProfile.timezone.name ||
        this.getUserProfile.timezone.offset === undefined ||
        this.getUserProfile.timezone.offset === null
      ) {
        return false;
      }
      return true;
    }
  },
  async mounted() {
    if (!localStorage.getItem(`lang`)) {
      localStorage.setItem(`lang`, this.$i18n.locale);
    }

    const langPref =
      localStorage.getItem(`lang`) ||
      (this.getUserCountryName.toLowerCase() === "india" ? "en" : "es");

    this.$i18n.locale = commonUtils.validateLanguage(langPref);
    this.setCurrUserLocation(this.getURLLocationId);
    this.setURLparams({
      location_id: this.getCurrUserLocationId
    });
  },
  created() {
    window.addEventListener("offline", e => {
      Notification.closeAll();
      this.$notify({
        title: this.$t("failed"),
        message: this.$t("your_are_offline"),
        duration: 0,
        type: "error"
      });
    });
    window.addEventListener("online", e => {
      Notification.closeAll();
      this.$notify({
        title: this.$t("online"),
        message: this.$t("your_are_online"),
        duration: 5000,
        type: "success"
      });
    });
  },
  methods: {
    ...mapActions("user", {
      fetchUserLocations: "fetchUserLocations",
      fetchUserProfile: "fetchUserProfile",
      setCurrUserLocation: "setCurrUserLocation"
    }),
    ...mapActions("header", {
      changeSideNavStatus: "changeSideNavStatus"
    }),
    setURLparams(newQueryParams) {
      if (
        hashSum(this.$route.query) ===
        hashSum({ ...this.$route.query, ...newQueryParams })
      ) {
        return;
      }
      this.$router.push({ query: { ...this.$route.query, ...newQueryParams } });
    },
    toggleNavigation: function() {
      this.changeSideNavStatus(!this.getSideNavStatus);
    },
    async logout() {
      await this.$store.dispatch("auth/signOut");
      this.$router.push("/");
    }
  }
};
</script>

<style lang="scss">
.container--main {
  $navigation-width-onopen: 200px;
  $navigation-width-onclose: 50px;
  display: grid;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  grid: 1fr / auto 1fr 0;
  .header__nav {
    height: var(--header-height) !important;
    position: relative;
    border-bottom: 1px solid #dcdfe6;
    top: 0px;
    left: 0px;
    right: 0px;
  }
  .nav__controller {
    position: relative;
    display: inline-flex;
    align-items: center;
    font-size: 25px;
    z-index: 1000;
    padding: 0px;
    margin-right: 0.7em;
    border-width: 1px;
    border-color: #e6e6e6;
    border-radius: 5px;
  }
  .nav__controller--opened {
    left: $navigation-width-onopen;
  }
  .nav__controller--closed {
    // left: -10px;
    left: 50px;
  }
  .aside__nav {
    position: relative;
    z-index: 2001;
    height: 100vh;
  }
  .aside-nav-opener {
    z-index: 1000;
    height: 100vh;
    position: absolute;
    width: 6px;
    pointer-events: all;
  }
  .aside__nav .el-scrollbar {
    height: 100%;
    box-sizing: border-box;
    .el-scrollbar__wrap {
      overflow-x: hidden;
    }
  }
  .aside__nav--closed {
    width: $navigation-width-onclose !important;
    transition: 0.4s width ease-in-out, 0.4s padding-left ease-in-out,
      0.4s padding-right ease-in-out;
    transition-property: width, padding-left, padding-right;
    transition-duration: 0.4s, 0.4s, 0.4s;
    transition-timing-function: ease-in-out, ease-in-out, ease-in-out;
    transition-delay: 0s, 0s, 0s;
    .el-submenu__title {
      display: none;
    }
    .navigation-menu .el-submenu:nth-last-child(2) {
      margin: 0;
    }
  }
  .aside__nav--opened {
    width: $navigation-width-onopen !important;
    transition: 0.4s width ease-in-out, 0.4s padding-left ease-in-out,
      0.4s padding-right ease-in-out;
    transition-property: width, padding-left, padding-right;
    transition-duration: 0.4s, 0.4s, 0.4s;
    transition-timing-function: ease-in-out, ease-in-out, ease-in-out;
    transition-delay: 0s, 0s, 0s;
  }
  .container-body {
    height: 100%;
  }
  .container-body__main {
    flex-grow: 1;
    height: 0px;
    min-height: 0px;
    padding: 0px 0px 0px 0px;
    overflow: hidden;
  }
  .dialog-main-item-title {
    padding-bottom: 20px;
  }
  .el-timeline .el-timeline-item:last-child {
    padding-bottom: unset;
  }
  .el-loading-mask {
    background-color: hsla(0, 0%, 100%, 1);
  }
}
</style>
