import {
  getRanges,
  getRangeValue
} from "../components/PondPerformanceCard/utils";
export const FEED_CONSUMPTION_FILTERS = {
  feeding_area: false,
  feeding_frequency: "daily",
  feeding_mode: "ALL"
};

export const AVERAGE_WEEKLY_GROWTH_FILTERS = { abw_input: 0.1 };

export const POND_PERFORMANCE_FILTERS = {
  abw_ranges: [
    getRangeValue(0, 5, false, true),
    getRangeValue(5, 10, false, false),
    getRangeValue(10, 20, false, false),
    getRangeValue(20, 30, false, false),
    getRangeValue(30, null, true, false)
  ],
  doc_ranges: getRanges(5, 0, 30, 30)
};

const defaultDasboardFilters = {
  FEED_CONSUMPTION: FEED_CONSUMPTION_FILTERS,
  AVERAGE_WEEKLY_GROWTH: AVERAGE_WEEKLY_GROWTH_FILTERS,
  POND_PERFORMANCE: POND_PERFORMANCE_FILTERS
};
export default defaultDasboardFilters;
