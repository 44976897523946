/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: pondFeeding.js
Description: This file contains all the vuex store functions used in Pond Feeding page
*/
import dateUtils from "@/utils/dateUtils";
import filterUtils from "../../utils/filterUtils";
import * as IntervalTree from "@davidisaaclee/interval-tree";
import {
  validateTSByChangingOCF,
  calcFeasibleOCFForGivenTS
} from "@/utils/scheduleParamsCalculationUtils";
import { DAY_TIME_IN_MS, alphaNumericComparator } from "@/utils/commonUtils";
import {
  INIT_OCF_VALUE,
  POND_TS_STATUS,
  PM_MODES,
  ALLOWED_PM_MODES_POND_FEEDING
} from "@/constants/schedule";
import { cloneDeep, get as lodashGet, round as lRound } from "lodash";
import PondsService from "../../services/PondsService";
import { MIN_TOTAL_FEED_VALUE_ST_MODE } from "../../constants/schedule";

export default {
  namespaced: "pondFeeding",
  state: {
    pondIdWithDetails: {},
    pmIdWithDetails: {},
    arrFtDetails: [],
    objFeedTypes: {},
    dateRange: [],
    selectedFt: undefined,
    objPondIdSched: [],
    pondIdToKGDT: {},
    userTimeZoneString: "UTC",
    dateRangeQueryType: "TODAY",
    pondIdTFCache: {},
    pondIdFeedTypeCache: {},
    pmDataWithDetails: [],
    feedTypeChanged: []
  },
  getters: {
    getDateRangeQueryType: function(state) {
      return state.dateRangeQueryType;
    },
    getCurrUserLocation: function(state, getters, rootState, rootGetters) {
      return rootGetters["user/getCurrUserLocation"];
    },
    getMapPmIdMode: function(state, getters, rootState, rootGetters) {
      return rootGetters["pondmother/getMapPmIdMode"];
    },
    getPondIdToPondDetails: function(state, getters, rootState, rootGetters) {
      return state.pondIdWithDetails;
    },
    getSelectedFt(state, getters) {
      return state.selectedFt;
    },
    getSelectedFtTS(state, getters) {
      return (getters.getSelectedFt || { timeSlots: [] }).timeSlots;
    },
    getArrScheds(state, getters) {
      return Object.values(state.objPondIdSched).sort((a, b) => {
        return alphaNumericComparator(a.pond_title, b.pond_title);
      });
    },
    getCurrDayExistSchedules(state, getters, rootState, rootGetters) {
      const userTimeZoneString = rootGetters["user/getUserTimeZoneString"];
      const currDayInSecs = dateUtils
        .startOfDay(dateUtils.getCurrDateMSInGivenTZ(userTimeZoneString))
        .getTime();
      const currDaySchedules = Object.values(
        getters.getExistSchedDtSecsToPondIdToPondSched
      )
        .filter(x => x.date_secs === currDayInSecs)
        .reduce((acc, x) => {
          acc[x.pond_id] = x;
          return acc;
        }, {});
      return currDaySchedules;
    },
    getTSIdsPmIdsByPondIdsAndActionStatus: function(state, getters) {
      const currDayExistSchedules = getters.getCurrDayExistSchedules;
      return (pondId, action) => {
        const actionToInverseAction = {
          STOPPED: [POND_TS_STATUS.RUNNING, POND_TS_STATUS.PAUSED],
          RUNNING: [POND_TS_STATUS.PAUSED],
          PAUSED: [POND_TS_STATUS.RUNNING]
        };
        const inverseAction = actionToInverseAction[action];
        const sched = currDayExistSchedules[pondId];
        return sched.time_slots
          .map(ts => {
            return ts.pond_mothers
              .filter(
                x =>
                  x.managed_by === PM_MODES.SCHEDULE &&
                  (ts.running_mode === undefined ||
                    ts.running_mode === "NORMAL")
              )
              .filter(x => inverseAction.includes(x.status))
              .map(pm => {
                return {
                  schedule_id: sched._id,
                  time_slot_id: ts._id,
                  pond_mother_id: pm.pond_mother_id
                };
              });
          })
          .flat(1);
      };
    },
    getExistArrScheds: function(state, getters, rootState, rootGetters) {
      const userTimeZoneString = rootGetters["user/getUserTimeZoneString"];
      return (rootGetters["schedules/getArrSchedules"] || [])
        .filter(sched => sched.status !== "DELETED")
        .map(sched => {
          const newSched = JSON.parse(JSON.stringify(sched));
          const date = dateUtils.utcToZonedTime(
            dateUtils.castUserUTCToBrowserTZDate(sched.date, {
              timeZone: userTimeZoneString
            }),
            userTimeZoneString
          );
          newSched.date_secs = date.getTime();
          newSched.time_slots.forEach(ts => {
            ts.pond_mothers.forEach(pm => {
              pm.feed_gap = Math.round(pm.feed_gap / 60);
            });
          });
          return newSched;
        });
    },
    getExistSchedDtSecsToPondIdToPondSched: function(state, getters) {
      const dtSecsToPondIdToPondSchedId = {};
      getters.getExistArrScheds.forEach(sched => {
        const key = `${sched.date_secs}_${sched.pond_id}`;
        dtSecsToPondIdToPondSchedId[key] = {};
        dtSecsToPondIdToPondSchedId[key] = sched;
      });
      return dtSecsToPondIdToPondSchedId;
    },
    getDispensedFeedToPondId: function(state, getters) {
      const currDaySchedules = getters.getCurrDayExistSchedules;
      const pondIds = Object.keys(getters.getPondsAcceptsSchedules);
      return pondIds.reduce((acc, pondId) => {
        acc[pondId] = (
          currDaySchedules[pondId] || { dispensed_feed: 0 }
        ).dispensed_feed;
        return acc;
      }, {});
    },
    getObjScheds(state, getters) {
      return state.objPondIdSched;
    },
    getPondIdsSelected(state, getters) {
      const objScheds = getters.getObjScheds;
      return Object.keys(getters.getObjScheds).filter(
        pondId => objScheds[pondId].selected
      );
    },
    getSelectedFtTSFeedPercnt(state, getters) {
      const tsIdToFeedPercnt = {};
      getters.getSelectedFtTS.forEach(timeSlot => {
        tsIdToFeedPercnt[timeSlot._id] = timeSlot.feed_percentage;
      });
      return tsIdToFeedPercnt;
    },
    getSelectedFtPonds(state, getters) {
      return (getters.getSelectedFt || { ponds: [] }).ponds.map(x => x._id);
    },
    getFTIdToFTDetails: function(state) {
      const ftIdToFtDetails = {};
      state.arrFtDetails.forEach(ft => {
        ftIdToFtDetails[ft._id] = ft;
      });
      return ftIdToFtDetails;
    },
    getIsFTAvailable: function(state) {
      return state.arrFtDetails.length > 0;
    },
    getObjFeedTypes: function(state, getters) {
      return state.objFeedTypes;
    },
    getArrFeedTypes: function(state, getters) {
      return Object.values(state.objFeedTypes);
    },
    getDateRange: function(state, getters) {
      return state.dateRange;
    },
    getPondTitlesOtherThanSchedMode: function(state, getters) {
      const pondIdWithDetails = getters.getPondIdToPondDetails;

      const pondIdInSchedMode = Object.keys(getters.getPondsAcceptsSchedules);
      const pondTitles = [];
      Object.keys(pondIdWithDetails).forEach(pondId => {
        const pond = pondIdWithDetails[pondId];
        if (pondIdInSchedMode.indexOf(pond._id) === -1) {
          pondTitles.push(pond.title);
        }
      });
      return pondTitles;
    },
    mapPmDataWithPondIdMode(state, getters, rootState, rootGetters) {
      const objPondIdPmMode = {};
      const ponds = rootGetters["pond/getPonds"];
      ponds.forEach(pond => {
        objPondIdPmMode[pond._id] = [
          "SCHEDULE",
          "BASIC",
          "AUTOMATIC",
          "HYBRID",
          "NO_MODE"
        ].reduce((acc, curr) => {
          if (curr === "HYBRID") {
            acc[curr] = {
              SCHEDULE: [],
              AUTOMATIC: []
            };
          } else {
            acc[curr] = [];
          }
          return acc;
        }, {});
        pond.pond_mothers.reduce((acc, currPondPm) => {
          const pmData =
            rootGetters["pondmother/getObjPmIdPm"][currPondPm.pond_mother_id];
          if (pmData.mode === "HYBRID") {
            pmData.allowedModes.forEach(allowMode => {
              acc[pond._id].HYBRID[allowMode].push(pmData);
            });
          } else {
            acc[pond._id][pmData.mode].push(pmData);
          }
          return acc;
        }, objPondIdPmMode);
      });
      return objPondIdPmMode;
    },
    getPondsAcceptsSchedules: function(state, getters, rootState, rootGetters) {
      const pondIdToPondDetails = cloneDeep(
        rootGetters["pond/getPonds"]
      ).reduce((Obj, pond) => {
        const mappedPmData = getters.mapPmDataWithPondIdMode[pond._id];
        const doesPondHasAllowedModePms = ALLOWED_PM_MODES_POND_FEEDING.some(
          key => lodashGet(mappedPmData, key, []).length > 0
        );
        if (doesPondHasAllowedModePms) {
          pond.pond_mothers = getters.mapPmDataWithPondIdMode[pond._id];
          Obj[pond._id] = pond;
        }
        return Obj;
      }, {});
      return pondIdToPondDetails;
    },
    getDateRangeDifference: function(state, getters) {
      const dateRange = getters.getDateRange;
      if (dateRange.length) {
        return (
          dateUtils.differenceInDays(
            dateUtils.parse(dateRange[1], "yyyy-MM-dd", new Date()),
            dateUtils.parse(dateRange[0], "yyyy-MM-dd", new Date())
          ) + 1
        );
      }
      return 1;
    },

    isTSValidInGivenPond(state, getters, rootState, rootGetters) {
      return (queryTS, pondId) => {
        const userTimeZoneString = rootGetters["user/getUserTimeZoneString"];
        const isToday = getters.getIsCurrentDay;
        const isFuture = getters.getIsFutureDay;
        const tsSTime = queryTS.s_time_secs;
        const tsETime = queryTS.e_time_secs;
        const currTimeInSecs = dateUtils.getCurrTimeSecsInGivenTZ(
          userTimeZoneString
        );
        const tsTree = getters.groupPondIdToRunningSchedules[pondId];
        const result = IntervalTree.queryIntersection(
          { low: tsSTime, high: tsETime },
          tsTree
        );
        return [
          Object.keys(result).length === 0,
          (tsSTime > currTimeInSecs && isToday) || isFuture
        ].every(x => x);
      };
    },
    getArrOfDaysFromDateRange: function(
      state,
      getters,
      rootState,
      rootGetters
    ) {
      const dateRange = getters.getDateRange;
      const dayDiff = getters.getDateRangeDifference;
      const userTimeZoneString = rootGetters["user/getUserTimeZoneString"];
      const isoDate = `${dateRange[0] || "1970-01-01"}T00:00:00.000Z`;
      const s_date_secs = dateUtils
        .utcToZonedTime(
          dateUtils.castUserUTCToBrowserTZDate(isoDate, {
            timeZone: userTimeZoneString
          }),
          userTimeZoneString
        )
        .getTime();
      const limit = s_date_secs + dayDiff * DAY_TIME_IN_MS;
      const incrmntVal = DAY_TIME_IN_MS;
      const arrDays = [s_date_secs];
      for (let day = s_date_secs + incrmntVal; day < limit; day += incrmntVal) {
        arrDays.push(day);
      }
      return arrDays.sort();
    },
    getIsCurrentDay(state, getters) {
      return getters.getDateRangeQueryType === "TODAY";
    },
    getIsFutureDay(state, getters) {
      return getters.getDateRangeQueryType === "FUTURE";
    },
    noPondSelected(state, getters, rootState, rootGetters) {
      return (
        Object.values(state.objPondIdSched).filter(sched => sched.selected)
          .length === 0
      );
    },
    getIsPondHasRunningScheds(state, getters, rootState, rootGetters) {
      return pondId => {
        if (!getters.getIsCurrentDay) return false;
        const userTimeZoneString = rootGetters["user/getUserTimeZoneString"];
        const currDaySecs = getters.getArrOfDaysFromDateRange[0];
        const key = `${currDaySecs}_${pondId}`;
        const existPondScheds = getters.getExistSchedDtSecsToPondIdToPondSched;
        if (!existPondScheds[key]) return false;
        const currDayPondSched = existPondScheds[key];
        const currTimeSecs = dateUtils.getCurrTimeSecsInGivenTZ(
          userTimeZoneString
        );
        if (!currDayPondSched) return false;
        const timeSlot = cloneDeep(currDayPondSched.time_slots)
          .sort((a, b) => a.e_time_in_seconds - b.s_time_in_seconds)
          .find(ts => ts.e_time_in_seconds > currTimeSecs);
        if (!timeSlot) return false;
        if (timeSlot.s_time_in_seconds < currTimeSecs) {
          return true;
        }
        return false;
      };
    },
    groupPondIdToRunningSchedules(state, getters, rootState, rootGetters) {
      const pondDetails = getters.getPondsAcceptsSchedules;
      return Object.keys(pondDetails).reduce((acc, pondId) => {
        if (!getters.getIsCurrentDay) return acc;
        const userTimeZoneString = rootGetters["user/getUserTimeZoneString"];
        const currDaySecs = getters.getArrOfDaysFromDateRange[0];
        const key = `${currDaySecs}_${pondId}`;
        const existPondScheds = getters.getExistSchedDtSecsToPondIdToPondSched;
        if (!existPondScheds[key]) return acc;
        const currDayPondSched = existPondScheds[key];
        const currTimeSecs = dateUtils.getCurrTimeSecsInGivenTZ(
          userTimeZoneString
        );
        if (!currDayPondSched) return acc;
        const pondPms = pondDetails[pondId].pond_mothers;
        const filteredPondPms = ALLOWED_PM_MODES_POND_FEEDING.map(key =>
          lodashGet(pondPms, key, [])
        );
        const setPms = filteredPondPms.flat(1).reduce((accSet, x) => {
          accSet.add(x._id);
          return accSet;
        }, new Set());
        const filteredTimeSlots = cloneDeep(currDayPondSched.time_slots)
          .filter(ts =>
            ts.pond_mothers.map(x => x.pond_mother_id).some(x => setPms.has(x))
          )
          .sort((a, b) => a.e_time_in_seconds - b.s_time_in_seconds)
          .filter(
            ts =>
              ts.s_time_in_seconds <= currTimeSecs &&
              ts.e_time_in_seconds > currTimeSecs
          );
        let timeSlotTree = IntervalTree.empty;
        timeSlotTree = filteredTimeSlots.reduce(
          (tree, ts) =>
            IntervalTree.insert(
              {
                id: ts._id,
                status: ts.status,
                s_time: ts.s_time,
                e_time: ts.e_time,
                range: {
                  low: ts.s_time_in_seconds,
                  high: ts.e_time_in_seconds
                }
              },
              tree
            ),
          timeSlotTree
        );
        acc[pondId] = timeSlotTree;
        return acc;
      }, {});
    },
    getCountOfValidTimeslotsForPond(state, getters, rootState, rootGetters) {
      return pondId => {
        if (!getters.getIsCurrentDay) return false;
        const userTimeZoneString = rootGetters["user/getUserTimeZoneString"];
        const pondSched = getters.getObjScheds[pondId];
        const runningSchedulesTree =
          getters.groupPondIdToRunningSchedules[pondId];
        const currTimeSecs = dateUtils.getCurrTimeSecsInGivenTZ(
          userTimeZoneString
        );
        const remainTs = [];
        pondSched.time_slots.forEach((ts, index) => {
          const validateTS = queryTS => {
            const tsSTime = queryTS.s_time_secs;
            const tsETime = queryTS.e_time_secs;
            const tsTree = runningSchedulesTree;
            const result = IntervalTree.queryIntersection(
              { low: tsSTime, high: tsETime },
              tsTree
            );
            return tsSTime > currTimeSecs && Object.keys(result).length === 0;
          };
          if (validateTS(ts)) {
            remainTs.push(ts);
          }
        });
        return remainTs.length;
      };
    },
    getPondTFCache(state) {
      return state.pondIdTFCache;
    },
    getPondFeedTypeCache(state) {
      return state.pondIdFeedTypeCache;
    },
    getPondIdToKgDispMap(state) {
      return state.pondIdToKGDT;
    },
    getFeedTypeChanged(state) {
      return state.feedTypeChanged;
    }
  },
  mutations: {
    INIT_POND_ID_TO_KGDT(state) {
      const pondIdWithDetails = state.pondIdWithDetails;
      const pmIdWithDetails = state.pmIdWithDetails;
      const pondIdToKGDT = {};
      Object.values(pondIdWithDetails).forEach(pond => {
        pondIdToKGDT[pond._id] = { pm: 0, feed: 0 };
        pond.pond_mothers.forEach(pm => {
          pondIdToKGDT[pond._id].pm = Math.max(
            pondIdToKGDT[pond._id].pm,
            pmIdWithDetails[pm._id]
              ? pmIdWithDetails[pm._id].settings.kg_dispense_time_sec
              : 0
          );
        });
      });
      state.pondIdToKGDT = pondIdToKGDT;
    },
    SET_POND_ID_POND_DETAILS(state, pondIdWithDetails) {
      state.pondIdWithDetails = pondIdWithDetails;
    },
    SET_PM_ID_PM_DETAILS(state, pmIdWithDetails) {
      state.pmIdWithDetails = pmIdWithDetails;
    },
    SET_PM_DATA_PM_DETAILS(state, pmDataWithDetails) {
      state.pmDataWithDetails = pmDataWithDetails;
    },
    SET_ARR_FT(state, arrFtDetails) {
      state.arrFtDetails = arrFtDetails;
    },
    SET_FEED_TYPE_ID_FEED_DETAILS(state, arrFeedTypes) {
      const feedTypeIdToFeedDetails = {};
      arrFeedTypes.forEach(feed => {
        feed.kg_dispense_time_sec = feed.kg_dispensed_time;
        feedTypeIdToFeedDetails[feed._id] = feed;
      });
      state.objFeedTypes = feedTypeIdToFeedDetails;
    },
    SET_DATE_RANGE(state, dateRange) {
      state.dateRange = dateRange;
    },
    SET_SELECTED_FT(state, ft) {
      state.selectedFt = ft;
    },
    SET_OBJ_SCHEDULES(state, objPondIdScheds) {
      state.objPondIdSched = objPondIdScheds;
    },
    CACHE_TF_AT_POND(state, { pondId, updtVal }) {
      state.pondIdTFCache[pondId] = updtVal;
    },
    CACHE_FEED_TYPE_AT_POND(state, { pondId, updtVal }) {
      state.pondIdFeedTypeCache[pondId] = updtVal;
    },
    CLEAR_CACHE_TF_AT_POND(state) {
      state.pondIdTFCache = {};
    },
    CLEAR_CACHE_FEED_TYPE_AT_POND(state) {
      state.pondIdFeedTypeCache = {};
    },
    CHANGE_TF_AT_TS(state, { pondId, tsPos, updtVal }) {
      const pond = state.pondIdWithDetails[pondId];
      const pmCount = pond.pond_mothers.length;
      const objPondIdSched = state.objPondIdSched;
      const currSched = objPondIdSched[pondId];
      const timeSlots = currSched.time_slots;
      const tsToChange = timeSlots[tsPos];
      if (isNaN(+updtVal) || +updtVal < 0) {
        tsToChange.feed = updtVal;
        return;
      } else {
        const prevVal = tsToChange.feed;
        currSched.feed -= prevVal;
        tsToChange.feed = +(+updtVal).toFixed(2);
        currSched.feed += +(+updtVal).toFixed(2);
      }
      const pondIdToKGDT = state.pondIdToKGDT;
      let kgdt = pondIdToKGDT[pondId].pm;
      if (currSched.feed_type_id) {
        kgdt = pondIdToKGDT[pondId].feed;
      }
      const TFA = timeSlots.map(ts => ts.feed / pmCount);
      const TTA = timeSlots.map(ts => (ts.e_time_secs - ts.s_time_secs) / 60);
      const ocfCalculated = calcFeasibleOCFForGivenTS(TFA, TTA, kgdt);
      currSched.ocf = ocfCalculated;
      state.objPondIdSched = Object.assign({}, state.objPondIdSched);
    },
    CHANGE_TF_AT_SCHED(state, { pondId, updtVal, runningSchedulesTree }) {
      const pond = state.pondIdWithDetails[pondId];
      const pmCount = pond.pond_mothers.length;
      const objPondIdSched = state.objPondIdSched;
      const currSched = objPondIdSched[pondId];
      const dateRangeQueryType = state.dateRangeQueryType;
      const timeSlots = currSched.time_slots;
      currSched.error_message.feed = "";
      currSched.error_message.feed_type = "";
      currSched.error_message.ocf = "";
      if (isNaN(+updtVal) || +updtVal < 0) {
        currSched.feed = updtVal;
        return;
      } else {
        currSched.feed = +(+updtVal).toFixed(2);
      }
      const userTimeZoneString = state.userTimeZoneString;
      const currTimeInSecs = dateUtils.getCurrTimeSecsInGivenTZ(
        userTimeZoneString
      );
      const isCurrentDay = dateRangeQueryType === "TODAY";
      timeSlots.forEach((ts, index) => {
        const canAllocateTFForCurrentDay = queryTS => {
          if (queryTS.auto_disabled) return false;
          const tsSTime = queryTS.s_time_secs;
          const tsETime = queryTS.e_time_secs;
          const tsTree = runningSchedulesTree;
          const result = IntervalTree.queryIntersection(
            { low: tsSTime, high: tsETime },
            tsTree
          );
          return tsSTime > currTimeInSecs && Object.keys(result).length === 0;
        };
        if (isCurrentDay && !canAllocateTFForCurrentDay(ts)) {
          return;
        }
        const skipHybridAutoDisabledFields = ts.auto_disabled;
        if (skipHybridAutoDisabledFields) return;
        const tsSlotFeed = ts.feed_percentage * 0.01 * updtVal;
        ts.feed = tsSlotFeed;
      });
      const pondIdToKGDT = state.pondIdToKGDT;
      let kgdt = pondIdToKGDT[pondId].pm;
      if (currSched.feed_type_id) {
        kgdt = pondIdToKGDT[pondId].feed;
      }
      const TFA = timeSlots.map(ts => ts.feed / pmCount);
      const TTA = timeSlots.map(ts => (ts.e_time_secs - ts.s_time_secs) / 60);
      const ocfCalculated = calcFeasibleOCFForGivenTS(TFA, TTA, kgdt);
      currSched.ocf = ocfCalculated;
      state.objPondIdSched = Object.assign({}, state.objPondIdSched);
    },
    CHANGE_FEED_TYPE_AT_SCHED(state, { pondId, updtVal }) {
      if (!updtVal) return;
      const pond = state.pondIdWithDetails[pondId];
      const pmCount = pond.pond_mothers.length;
      const objPondIdSched = state.objPondIdSched;
      const objFeedTypes = state.objFeedTypes;
      const currSched = objPondIdSched[pondId];
      const timeSlots = currSched.time_slots;
      currSched.feed_type_id = updtVal;
      const kgdt = objFeedTypes[updtVal].kg_dispensed_time;
      const TFA = timeSlots.map(ts => ts.feed / pmCount);
      const TTA = timeSlots.map(ts => (ts.e_time_secs - ts.s_time_secs) / 60);
      const ocfCalculated = calcFeasibleOCFForGivenTS(TFA, TTA, kgdt);
      currSched.ocf = ocfCalculated;
      // if (!state.pondIdToKGDT[pondId]) {
      //   state.pondIdToKGDT[pondId] = {};
      // }
      state.pondIdToKGDT[pondId].feed = kgdt;
      state.pondIdToKGDT = Object.assign({}, state.pondIdToKGDT);
      state.objPondIdSched = Object.assign({}, state.objPondIdSched);
    },
    HANDLE_CHANGE_FEED_TYPE(state, { feedTypeChanged }) {
      state.feedTypeChanged = feedTypeChanged;
    },
    CHANGE_OCF_AT_SCHED(state, { pondId, updtVal }) {
      const objPondIdSched = state.objPondIdSched;
      const currSched = objPondIdSched[pondId];
      if (isNaN(+updtVal) || +updtVal < 0) {
        currSched.ocf = updtVal;
        return;
      } else {
        currSched.ocf = +(+updtVal).toFixed(2);
      }
      state.objPondIdSched = Object.assign({}, state.objPondIdSched);
    },
    CHANGE_SELECTION_OF_ALL_POND_SCHEDS(state, isSelectedAll) {
      const objPondIdSched = state.objPondIdSched;
      Object.values(objPondIdSched).forEach(sched => {
        sched.selected = isSelectedAll;
      });
      state.objPondIdSched = Object.assign({}, state.objPondIdSched);
    },
    CHANGE_SELECTION_OF_POND_SCHED(state, { pondId, updtVal }) {
      state.objPondIdSched[pondId].selected = updtVal;
      state.objPondIdSched = Object.assign({}, state.objPondIdSched);
    },
    SET_USER_TIME_ZONE_STRING: function(state, userTimeZoneString) {
      state.userTimeZoneString = userTimeZoneString;
    },
    VALIDATE_TS_FOR_ALL_SELECTED_PONDS: function(
      state,
      { groupPondIdToRunningSchedules }
    ) {
      const objPondIdScheds = state.objPondIdSched;
      const userTimeZoneString = state.userTimeZoneString;
      const dateRangeQueryType = state.dateRangeQueryType;
      const allSelectedPondIds = Object.values(objPondIdScheds)
        .filter(sched => sched.selected)
        .map(sched => sched.pond_id);
      const length = allSelectedPondIds.length;
      let indexPond;
      const errors = [];
      for (indexPond = 0; indexPond < length; indexPond++) {
        const pondId = allSelectedPondIds[indexPond];
        const pondTitle = state.pondIdWithDetails[pondId].title;
        const pondMother = state.pondIdWithDetails[pondId].pond_mothers.length;
        const sched = objPondIdScheds[pondId];
        const ocfValue = objPondIdScheds[pondId].ocf / 1000;
        const runningSchedulesTree = groupPondIdToRunningSchedules[pondId];
        const currTimeInSecs = dateUtils.getCurrTimeSecsInGivenTZ(
          userTimeZoneString
        );
        const isTSAvailable = queryTS => {
          const tsSTime = queryTS.s_time_secs;
          const tsETime = queryTS.e_time_secs;
          const tsTree = runningSchedulesTree;
          const result = IntervalTree.queryIntersection(
            { low: tsSTime, high: tsETime },
            tsTree
          );
          return tsSTime > currTimeInSecs && Object.keys(result).length === 0;
        };
        const arrTS = sched.time_slots;
        sched.error_message.feed = "";
        if (isNaN(+sched.feed)) {
          errors.push({
            type: "feed",
            pond_title: pondTitle,
            message: "PM_tf_must_not_be_empty"
          });
        }
        sched.error_message.ocf = "";
        if (!sched.ocf) {
          errors.push({
            type: "ocf",
            pond_title: pondTitle,
            message: "PM_ocf_not_zero"
          });
        }
        const pondIdToKGDT = state.pondIdToKGDT;
        let kgdt = pondIdToKGDT[pondId].pm;
        if (sched.feed_type_id) {
          kgdt = pondIdToKGDT[pondId].feed;
        }
        let tsPos;
        let cntTSWithOutFeedAndNotAvailable = 0;
        for (tsPos = 0; tsPos < arrTS.length; tsPos++) {
          const ts = arrTS[tsPos];
          if (dateRangeQueryType === "TODAY" && !isTSAvailable(ts)) {
            ts.error_message = "";
            cntTSWithOutFeedAndNotAvailable++;
            continue;
          }
          if (isNaN(+ts.feed)) {
            errors.push({
              type: "timeslot_feed",
              pond_title: pondTitle,
              time_slot_id: tsPos + 1,
              message: "PM_tf_must_not_be_empty"
            });
            continue;
          }
          if (ts.feed === 0) {
            cntTSWithOutFeedAndNotAvailable++;
            continue;
          }
          if (ts.mode === "SCHEDULE") {
            const rndTSFeed = lRound(ts.feed, 2);
            const rndPmsTSFeed = lRound(ts.pmCount * ocfValue, 2);
            if (ocfValue > 0 && rndPmsTSFeed > rndTSFeed) {
              errors.push({
                type: "timeslot_min_feed",
                threshold: rndPmsTSFeed,
                pond_title: pondTitle,
                time_slot_id: tsPos + 1,
                message: "Comn_must_greater_than_or_equal"
              });
              continue;
            }
            const TT = (ts.e_time_secs - ts.s_time_secs) / 60;
            const TF = lRound(ts.feed / ts.pmCount, 2);
            const validateResult = validateTSByChangingOCF(
              TT,
              TF,
              sched.ocf,
              kgdt
            );
            if (validateResult === -1) {
              errors.push({
                type: "timeslot_feed",
                pond_title: pondTitle,
                time_slot_id: tsPos + 1,
                message: "change_one_cycle_feed_or_total_feed_for_schedule"
              });
            } else if (validateResult === 1) {
              errors.push({
                type: "timeslot_feed",
                pond_title: pondTitle,
                time_slot_id: tsPos + 1,
                message: "PM_ocf_must_greater"
              });
            } else {
              ts.error_message = "";
            }
          } else if (ts.mode === "AUTOMATIC") {
            const rndTSFeed = lRound(ts.feed, 2);
            const rndPmsTSFeed = lRound(ts.pmCount * MIN_TOTAL_FEED_VALUE_ST_MODE, 2);
            if (ocfValue > 0 && rndPmsTSFeed > rndTSFeed) {
              errors.push({
                type: "timeslot_min_feed",
                threshold: rndPmsTSFeed,
                pond_title: pondTitle,
                time_slot_id: tsPos + 1,
                message: "Comn_must_greater_than_or_equal"
              });
              continue;
            }
          }
        }
        if (cntTSWithOutFeedAndNotAvailable === arrTS.length) {
          errors.push({
            type: "feed",
            pond_title: pondTitle,
            threshold: filterUtils.digitPrecision(ocfValue * pondMother, 1),
            message: "all_timeslots_feed_empty"
          });
        }
      }
      state.objPondIdSched = Object.assign({}, state.objPondIdSched);
      if (errors.length > 0) {
        throw {
          type: "FAIL_SAVE",
          errors: errors
        };
      }
    },
    SET_DATE_RANGE_QUERY_TYPE: function(state, dateRangeQueryType) {
      state.dateRangeQueryType = dateRangeQueryType;
    }
  },
  actions: {
    initUserTimeZoneString: function(context) {
      context.commit(
        "SET_USER_TIME_ZONE_STRING",
        context.rootGetters["user/getUserTimeZoneString"]
      );
    },
    fetchAllPondDetails: async function(context) {
      const location = context.getters.getCurrUserLocation;
      await context.dispatch(
        "pond/fetchAllPonds",
        {
          location_id: location._id,
          get_all: true,
          status: ["ACTIVE"],
          include: ["title", "pond_mothers", "feed_type"]
        },
        {
          root: true
        }
      );
      context.commit(
        "SET_POND_ID_POND_DETAILS",
        context.rootGetters["pond/getPondsObj"]
      );
    },
    fetchAllFeedTemplates: async function(context) {
      // const location = context.getters.getCurrUserLocation;
      // const changedDates = context.getters.getDateRange;
      await context.dispatch(
        "schedules/fetchAllFeedTemplates",
        {
          get_all: true
        },
        { root: true }
      );
      context.commit(
        "SET_ARR_FT",
        context.rootGetters["schedules/getArrFeedTemplates"]
      );
    },
    fetchAllFeedTypes: async function(context) {
      const location = context.getters.getCurrUserLocation;
      await context.dispatch(
        "resource/fetchAllResources",
        {
          location_id: location._id,
          type: "FEED",
          get_all: true
        },
        { root: true }
      );
      context.commit(
        "SET_FEED_TYPE_ID_FEED_DETAILS",
        context.rootGetters["resource/getArrResources"]
      );
    },
    fetchAllPondMothers: async function(context) {
      const location = context.getters.getCurrUserLocation;
      await context.dispatch(
        "pondmother/fetchAllPondMothers",
        {
          location_id: location._id,
          get_all: true
        },
        { root: true }
      );
      context.commit(
        "SET_PM_ID_PM_DETAILS",
        context.rootGetters["pondmother/getObjPmIdPm"]
      );
      context.commit(
        "SET_PM_DATA_PM_DETAILS",
        context.rootGetters["pondmother/getPondMothers"]
      );
    },
    fetchAllSchedDetails: async function(context) {
      const location = context.getters.getCurrUserLocation;
      const changedDates = context.getters.getDateRange;
      await context.dispatch(
        "schedules/fetchAllSchedules",
        {
          location_id: location._id,
          get_all: true,
          from_date: changedDates[0] + "T00:00:00.000Z",
          to_date: changedDates[1] + "T00:00:00.000Z"
        },
        {
          root: true
        }
      );
    },
    initPondIdToKGDT(context) {
      context.commit("INIT_POND_ID_TO_KGDT");
    },
    changeDateRange: async function(context, dateRange) {
      context.commit("SET_DATE_RANGE", dateRange);
      await context.dispatch("fetchAllSchedDetails");
    },
    changeDateQueryType: async function(context, dateRangeQueryType) {
      context.commit("SET_DATE_RANGE_QUERY_TYPE", dateRangeQueryType);
    },
    changeSelectedFT: async function(context, ft) {
      context.commit("SET_SELECTED_FT", ft);
    },
    changeSelectionPondSched: function(context, { pondId, updtVal }) {
      context.commit("CHANGE_SELECTION_OF_POND_SCHED", { pondId, updtVal });
    },
    changeTFAtTSInSched: function(context, { pondId, tsPos, updtVal }) {
      context.commit("CHANGE_TF_AT_TS", { pondId, tsPos, updtVal });
    },
    changeTFAtSched: function(context, { pondId, updtVal }) {
      const runningSchedulesTree =
        context.getters.groupPondIdToRunningSchedules[pondId];
      context.commit("CACHE_TF_AT_POND", {
        pondId,
        updtVal
      });
      context.commit("CHANGE_TF_AT_SCHED", {
        pondId,
        updtVal,
        runningSchedulesTree: runningSchedulesTree
      });
    },
    changeOCFAtSched: function(context, { pondId, updtVal }) {
      context.commit("CHANGE_OCF_AT_SCHED", { pondId, updtVal });
    },
    changeFeedTypeAtSched: function(context, { pondId, updtVal }) {
      context.commit("CACHE_FEED_TYPE_AT_POND", {
        pondId,
        updtVal
      });
      context.commit("CHANGE_FEED_TYPE_AT_SCHED", { pondId, updtVal });
    },
    handleChangeFeedType: function(context, { feedTypeChanged = [] }) {
      context.commit("HANDLE_CHANGE_FEED_TYPE", { feedTypeChanged });
    },
    changeSelectionOfAllPondSchedules: function(context, isSelectedAll) {
      context.commit("CHANGE_SELECTION_OF_ALL_POND_SCHEDS", isSelectedAll);
    },
    changeStatusPondSched: async function(context, { sched, status }) {
      const payload = context.getters.getTSIdsPmIdsByPondIdsAndActionStatus(
        sched.pond_id,
        status
      );
      await context.dispatch(
        "schedules/changePondMotherStatusInTimeSlot",
        {
          statusChangesPayload: payload,
          status
        },
        { root: true }
      );
      const dateRange = context.getters.getDateRange;
      await context.dispatch("changeDateRange", dateRange);
      await context.dispatch("initFTTableData");
    },
    initFTTableData: async function(context) {
      const pondDetails = context.getters.getPondsAcceptsSchedules;
      // const pondIdToPondDetails = context.get
      const timeslots = context.getters.getSelectedFtTS;
      const ponds = context.getters.getSelectedFtPonds;
      const resources = context.getters.getArrFeedTypes;
      let firstresource = { _id: undefined };
      if (resources.length > 0) {
        firstresource = resources[0];
      }
      console.assert(
        Object.values(pondDetails).length > 0,
        "No schedule Modes ponds"
      );
      const prepScheds = (arrFtTS = [], pondDetails) => {
        const sched = {};
        const pondId = pondDetails._id;
        const pondPms = pondDetails.pond_mothers;
        const schedulePmsCount =
          pondPms.SCHEDULE.length + pondPms.HYBRID.SCHEDULE.length;
        const hybridAutomaticPmsCount = pondPms.HYBRID.AUTOMATIC.length;
        sched.selected = ponds.indexOf(pondId) > -1;
        sched.pond_title = pondDetails.title;
        sched.pond_id = pondId;
        sched.feed_type_id = "";
        sched.feed = 0;
        sched.ocf = INIT_OCF_VALUE;
        sched.dispensed_feed = 0;
        sched.error_message = {
          feed_type: "",
          feed: "",
          ocf: ""
        };
        const time_slots = [];
        arrFtTS.forEach(ts => {
          const tempTS = {};
          tempTS.s_time = ts.s_time;
          tempTS.e_time = ts.e_time;
          tempTS.s_time_secs = ts.s_time_secs;
          tempTS.e_time_secs = ts.e_time_secs;
          tempTS.mode = ts.mode;
          tempTS.feed_percentage = ts.feed_percentage;
          tempTS.feed = 0;
          tempTS.pmCount =
            tempTS.mode === "SCHEDULE"
              ? schedulePmsCount
              : hybridAutomaticPmsCount;
          tempTS.error_message = "";
          tempTS.feeding_level = -1;
          if (tempTS.mode === "AUTOMATIC") {
            tempTS.feeding_level = ts.feeding_level;
            tempTS.auto_disabled = hybridAutomaticPmsCount === 0;
          }
          time_slots.push(tempTS);
        });
        sched.time_slots = time_slots;
        return sched;
      };
      const objPondIdScheds = {};
      Object.values(pondDetails).forEach(pond => {
        objPondIdScheds[pond._id] = prepScheds(timeslots, pond);
      });
      context.commit("SET_OBJ_SCHEDULES", objPondIdScheds);
      const objFeedTypes = context.getters.getObjFeedTypes;
      const objPondIdFeedType = context.getters.getPondFeedTypeCache;
      const initFeedTypeArr = Object.values(pondDetails).map(pond => {
        const existingFeedTypeId = objPondIdFeedType[pond._id];
        const getFeedId = () => {
          if (objFeedTypes[existingFeedTypeId] && existingFeedTypeId) {
            return existingFeedTypeId;
          }
          if (objFeedTypes[pond.feed_type] && pond.feed_type) {
            return pond.feed_type;
          }
          return firstresource._id;
        };
        return context.dispatch("changeFeedTypeAtSched", {
          updtVal: getFeedId(),
          pondId: pond._id
        });
      });
      await Promise.all(initFeedTypeArr);
    },
    clearCache(context) {
      context.commit("CLEAR_CACHE_TF_AT_POND");
      context.commit("CLEAR_CACHE_FEED_TYPE_AT_POND");
    },
    savePondSchedules: async function(context) {
      const objPondIdSched = context.getters.getObjScheds;
      const dateRange = context.getters.getDateRange;
      // const selectedFTTS = context.getters.getSelectedFtTS;
      const pondIdToPondDetails = context.getters.getPondsAcceptsSchedules;
      const arrDaysInSelectedRange = context.getters.getArrOfDaysFromDateRange;
      const dayToPondIdToExistSched =
        context.getters.getExistSchedDtSecsToPondIdToPondSched;
      const userTimeZoneString =
        context.rootGetters["user/getUserTimeZoneString"];
      const objSampleSchedPayload = {};
      Object.keys(objPondIdSched).forEach(pondId => {
        const tempSched = cloneDeep(objPondIdSched[pondId]);
        if (!tempSched.selected) return;
        const pondDetails = pondIdToPondDetails[pondId];
        const dateISOTimes = dateRange.map(x => x + "T00:00:00.000Z");
        tempSched.start_time = dateISOTimes[0];
        tempSched.end_time = dateISOTimes[0];
        const timeSlots = tempSched.time_slots;
        tempSched.time_slots = timeSlots.reduce((acc, ts, index) => {
          if (
            ts.feed <= 0 ||
            !context.getters.isTSValidInGivenPond(ts, pondId)
          ) {
            return acc;
          }
          // ts.s_time = selectedFTTS[index].s_time;
          // ts.e_time = selectedFTTS[index].e_time;
          ts.managed_by = PM_MODES[ts.mode];
          ts.status = "TO_BE_RUN";
          const pmCount = ts.pmCount;
          delete ts.pmCount;
          const pmArr = [];
          const pmFeed = pmCount === 0 ? 0 : lRound(ts.feed / pmCount, 2);
          const pondPms = pondDetails.pond_mothers;
          let tsPms = pondPms.HYBRID.AUTOMATIC;
          if (ts.mode === "SCHEDULE") {
            tsPms = [pondPms.SCHEDULE, pondPms.HYBRID.SCHEDULE].flat(1);
          }
          tsPms.forEach(pm => {
            const tempPm = {};
            tempPm.pond_mother_id = pm._id;
            if (ts.mode === "SCHEDULE") {
              tempPm.ocf = tempSched.ocf;
            }
            tempPm.feed = pmFeed;
            if (ts.mode === "AUTOMATIC") {
              tempPm.feeding_level = ts.feeding_level;
              tempPm.shrimp_talk_id = pm.shrimp_talk_id;
            }
            if (pm.mode === "HYBRID") {
              tempPm.running_mode = "HYBRID";
            }
            tempPm.managed_by = ts.managed_by;
            tempPm.status = "TO_BE_RUN";
            pmArr.push(tempPm);
          });
          ts.feed = pmFeed * pmCount;
          ts.pond_mothers = pmArr;
          acc.push(ts);
          return acc;
        }, []);
        delete tempSched.ocf;
        objSampleSchedPayload[pondId] = tempSched;
      });
      if (Object.keys(objSampleSchedPayload).length === 0) {
        throw {
          type: "store",
          message: "Comn_select_atleast_pond"
        };
      }
      const objActionBackendPayload = {};
      const format = "yyyy-MM-dd";
      arrDaysInSelectedRange.forEach(day => {
        Object.keys(objPondIdSched).forEach(pondId => {
          if (!objPondIdSched[pondId].selected) return;
          const key = `${day}_${pondId}`;
          const existingSchedule = dayToPondIdToExistSched[key];
          const sched = cloneDeep(objSampleSchedPayload[pondId]);
          const isCurrentDay = context.getters.getIsCurrentDay;
          sched.feed = sched.time_slots
            .map(x => x.feed)
            .reduce((acc, curr) => acc + curr);
          const action =
            isCurrentDay && existingSchedule
              ? "UPDATE_TODAY"
              : existingSchedule
              ? "UPDATE"
              : "CREATE";
          if (action !== "CREATE") {
            sched._id = existingSchedule._id;
            sched.date = dateUtils.formatTZ(+day, format, {
              timeZone: userTimeZoneString
            });
          } else {
            sched.start_time = dateUtils.formatTZ(+day, format, {
              timeZone: userTimeZoneString
            });
            sched.end_time = dateUtils.formatTZ(+day, format, {
              timeZone: userTimeZoneString
            });
          }
          if (!objActionBackendPayload[action]) {
            objActionBackendPayload[action] = [];
          }
          objActionBackendPayload[action].push(sched);
        });
      });
      const promiseArr = [];
      Object.keys(objActionBackendPayload).forEach(action => {
        promiseArr.push(
          context.dispatch("saveSchedules", {
            action,
            schedule: objActionBackendPayload[action]
          })
        );
      });
      await Promise.all(promiseArr);
      // update the kg feed dispensed
      const pondIdToKGDT = context.getters.getPondIdToKgDispMap;
      await PondsService.updateArrPondKgDispenseFeed(
        Object.keys(objPondIdSched)
          .filter(pondId => objPondIdSched[pondId].selected)
          .map(pondId => ({
            pond_id: pondId,
            kg_dispense_time:
              pondIdToKGDT[pondId].feed || pondIdToKGDT[pondId].pm
          }))
      );
      // clear pond feeding cache
      await context.dispatch("clearCache");
      await context.dispatch("changeDateRange", dateRange);
      await context.dispatch("initFTTableData");
    },
    saveSchedules: async function(context, { action, schedule }) {
      switch (action) {
        case "UPDATE":
          await context.dispatch("schedules/updateSchedules", schedule, {
            root: true
          });
          break;
        case "UPDATE_TODAY":
          await context.dispatch("schedules/updateTodaySchedules", schedule, {
            root: true
          });
          break;
        case "CREATE":
          await context.dispatch("schedules/createSchedules", schedule, {
            root: true
          });
          break;
      }
    }
  }
};
