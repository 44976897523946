<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: erDropdown.vue
Description: This file contains component which is the extension of the element ui drop down functionality to use as a base component
-->
<template>
  <el-dropdown
    @click.native="handleDropdownTriggerClick"
    @command="handleCommand"
    :placement="placement"
    :trigger="trigger"
    :hideOnClick="hideOnClick"
    :disabled="disabled"
    class="er-dropdown"
  >
    <div class="er-dropdown-trigger">
      <div class="er-dropdown-trigger__prefix" v-if="$slots.prefix">
        <slot name="prefix"></slot>
      </div>
      <div class="er-dropdown-trigger__title" v-if="$slots.title">
        <slot name="title"></slot>
      </div>
      <div class="er-dropdown-trigger__suffix">
        <slot name="suffix">
          <span ref="icon-caret" class="material-icons-outlined er-icon-caret">
            keyboard_arrow_up
          </span>
        </slot>
      </div>
    </div>
    <el-dropdown-menu
      slot="dropdown"
      :disabled="disabled"
      @visible="handleVisiblityChange"
      :class="`er-dropdown-menu ${popperClass}`"
    >
      <slot name="drop-down-items">
        <el-dropdown-item
          v-for="(value, key) in listOfItems"
          :key="`list-${key}`"
          :command="value"
        >
          <slot name="item-title" :item="{ value, key }">{{ key }}</slot>
        </el-dropdown-item>
      </slot>
    </el-dropdown-menu>
  </el-dropdown>
</template>
<script>
export default {
  props: {
    listOfItems: {
      required: true,
    },
    popperClass: {
      type: String,
      default: "",
    },
    trigger: {
      type: String,
      default: "click",
    },
    disabled: {
      default: false,
    },
    placement: {
      type: String,
      default: "bottom-end",
    },
    hideOnClick: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    handleCommand(command) {
      this.handleDropdownTriggerClick();
      this.$emit("item-click", command);
    },
    handleVisiblityChange(value) {
      if (this.$refs["icon-caret"]) {
        if (value) {
          this.$refs["icon-caret"].classList.add("is-reverse");
        } else {
          this.$refs["icon-caret"].classList.remove("is-reverse");
        }
      }
    },
    handleDropdownTriggerClick(value) {
      this.$emit("drop-down-trigger-click");
    },
  },
};
</script>

<style lang="scss">
.er-dropdown-menu {
  display: inline-block;
  position: relative;
  color: #606266;
  cursor: pointer;
  @include responsiveProperty(
    font-size,
    $app_font_size_1,
    $app_font_size_2,
    $app_font_size_3
  );

  .el-dropdown-menu__item {
    @include select-drop-down-item-style;
    position: relative;
    @include responsiveProperty(
      font-size,
      $app_font_size_1,
      $app_font_size_2,
      $app_font_size_3
    );
    @include responsiveProperty(height, 25px, 30px, 40px);
    @include responsiveProperty(line-height, 25px, 30px, 40px);

    *:not(:focus) {
      color: #606266;
      @include responsiveProperty(
        font-size,
        $app_font_size_1,
        $app_font_size_2,
        $app_font_size_3
      );
    }

    text-transform: capitalize;
    display: flex;
    align-items: center;

    .item-prefix {
      display: inline-flex;
      align-items: center;
    }

    &:focus,
    &:not(.is-disabled):hover {
      color: #606266;
    }
  }
}

.er-dropdown {
  color: #606266;
  @include responsiveProperty(
    font-size,
    $app_font_size_1,
    $app_font_size_2,
    $app_font_size_3
  );
  display: flex;

  .er-dropdown-trigger {
    display: inline-flex;
    align-items: center;
    cursor: pointer;

    .action-icon {
      cursor: pointer;
      color: black;
      opacity: 0.3;
      font-weight: 500;
      @include icon-size(icon-font);

      &:hover {
        opacity: 1;
        cursor: pointer;
        color: #000;
      }
    }
  }

  .er-dropdown-trigger__title {
    text-transform: capitalize;
  }

  .er-dropdown-trigger__prefix {
    text-align: center;

    [class^="material-icons-"] {
      @include icon-size(icon-font);
      color: $header-icon-color;
      font-weight: 400;
    }

    * {
      vertical-align: middle;
    }
  }

  .er-dropdown-trigger__suffix {
    text-align: center;

    [class^="material-icons-"] {
      @include icon-size(icon-font);
      color: #000;
      font-weight: 400;
    }

    * {
      vertical-align: middle;
    }
  }

  .er-dropdown-trigger__prefix + .er-dropdown-trigger__suffix,
  .er-dropdown-trigger__prefix + .er-dropdown-trigger__title,
  .er-dropdown-trigger__title + .er-dropdown-trigger__suffix {
    margin-left: 5px;
  }

  .er-dropdown-trigger__suffix {
    .er-icon-caret {
      transition: transform 0.3s;
      transform: rotate(180deg);
      color: #606266;
      cursor: pointer;

      &.is-reverse {
        transform: rotate(0deg);
        color: #606266;
      }
    }
  }
}
</style>
