<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: erButton.vue
Description: This file contains the button component which has additional functions than element ui button component
-->
<template>
  <el-button
    v-bind="$attrs"
    v-on="inputListeners"
    :title="$attrs.title || (getBtnObj || { label: '' }).label"
    :class="computedClass"
  >
    <slot name="btn-icon" v-if="$attrs.showIcon && !$attrs.loading">
      <template v-if="$attrs.icon">
        <i :class="$attrs.icon"></i>
      </template>
      <template v-else>
        <template v-if="getBtnObj.iconType === 'font'">
          <i
            :class="getBtnObj.style ? getBtnObj.style : 'material-icons-round'"
          >
            {{ getBtnObj.icon }}
          </i>
        </template>
        <template v-else>
          <img
            v-if="$attrs.btnType"
            :class="[
              'btn-icon-svg',
              $attrs.showLabel ? 'btn-icon-svg--prefix' : ''
            ]"
            :src="getBtnObj.icon"
          />
        </template>
      </template>
    </slot>
    <slot v-if="$attrs.showLabel">
      {{ (getBtnObj || { label: "no label" }).label }}
    </slot>
    <slot v-else></slot>
  </el-button>
</template>

<script>
export default {
  inheritAttrs: false,
  data: function() {
    return {};
  },
  computed: {
    inputListeners: function() {
      var vm = this;
      // `Object.assign` merges objects together to form a new object
      return Object.assign(
        {},
        // We add all the listeners from the parent
        this.$listeners,
        // Then we can add custom listeners or override the
        // behavior of some listeners.
        {
          // This ensures that the component works with v-model
          input: function(event) {
            vm.$emit("input", event);
          }
        }
      );
    },
    getBtnObj() {
      return this.btn_type_lang_key[this.$attrs.btnType];
    },
    btn_type_lang_key: function() {
      return {
        save: {
          label: this.$t("Comn_save"),
          icon: "done_all",
          iconType: "font"
        },
        cancel: {
          label: this.$t("Comn_cancel"),
          icon: "close",
          iconType: "font"
        },
        submit: {
          label: this.$t("Comn_submit"),
          icon: "el-icon-s-order",
          iconType: "font"
        },
        clear: {
          label: this.$t("clear"),
          icon: "clear_all",
          iconType: "font"
        },
        download: {
          label: this.$t("Comn_download"),
          icon: "cloud_download",
          iconType: "font"
        },
        draw: {
          label: this.$t("Map_draw_ur_own"),
          icon: "brush",
          iconType: "font"
        },
        email: {
          label: this.$t("Comn_email_address"),
          icon: "email",
          iconType: "font"
        },
        upload: {
          label: this.$t("Comn_upload"),
          icon: "cloud_upload",
          iconType: "font"
        },
        print: {
          label: this.$t("Comn_print"),
          icon: "print",
          iconType: "font"
        },
        refresh: {
          label: this.$t("Comn_reload"),
          icon: "refresh",
          iconType: "font"
        },
        edit: {
          label: this.$t("Comn_edit"),
          icon: "edit",
          iconType: "font",
          style: "material-icons-outlined"
        },
        add: {
          label: this.$t("Comn_add"),
          icon: "add",
          iconType: "font"
        },
        circle_add: {
          label: this.$t("Comn_add"),
          icon: "add_circle_outline",
          iconType: "font",
          style: "material-icons-outlined"
        },

        activate: {
          label: this.$t("Activate"),
          icon: "done",
          iconType: "font",
          style: "material-icons-outlined"
        },
        enable: {
          label: this.$t("Comn_enable"),
          icon: "done",
          iconType: "font",
          style: "material-icons-outlined"
        },
        circle_activate: {
          label: this.$t("Activate"),
          icon: "check_circle",
          iconType: "font",
          style: "material-icons-outlined"
        },
        confirm: {
          label: this.$t("Comn_add"),
          icon: "confirm",
          iconType: "font",
          style: "material-icons-outlined"
        },
        table_add: {
          label: this.$t("Comn_add"),
          icon: "add",
          iconType: "font"
        },
        delete: {
          label: this.$t("Comn_delete"),
          icon: "delete",
          iconType: "font",
          style: "material-icons-outlined"
        },
        calculate: {
          label: this.$t("Comn_calculate"),
          icon: "calculate",
          iconType: "font"
        },
        play: {
          label: this.$t("PM_play"),
          icon: "play_arrow",
          iconType: "font"
        },
        pause: {
          label: this.$t("PM_pause"),
          icon: "pause",
          iconType: "font"
        },
        stop: {
          label: this.$t("PM_stop"),
          icon: "stop",
          iconType: "font"
        },
        editSolid: {
          label: this.$t("Comn_edit"),
          icon: "edit",
          iconType: "font"
        },
        outlinePassword: {
          label: "Change Password",
          icon: "lock",
          iconType: "font",
          style: "material-icons-outlined"
        },
        outlineEdit: {
          label: "Edit Info",
          icon: "create",
          iconType: "font",
          style: "material-icons-outlined"
        },
        deleteForever: {
          label: this.$t("Comn_delete"),
          icon: "delete_forever",
          iconType: "font"
        },
        science: {
          label: this.$tc("Comn_test", 0),
          icon: "science",
          iconType: "font",
          style: "material-icons-outlined"
        }
      };
    },

    computedClass() {
      if (this.$attrs.btnType === "iconContainer") {
        return `er-button icon-container`;
      }
      const classList = ["er-button"];
      if (this.$attrs.showIcon) {
        const iconClass = `btn-icon-type-${
          this.btn_type_lang_key[this.$attrs.btnType].iconType
        }`;
        classList.push(iconClass);
      }
      if (this.$attrs.btnType) {
        const className = `er-button--${this.$attrs.btnType}`;
        classList.push(className);
      }
      if (this.$attrs.showIcon && this.$attrs.showLabel) {
        classList.push("er-button--icon-text");
      } else if (this.$attrs.showIcon) {
        classList.push("er-button--icon");
      } else if (this.$attrs.showLabel) {
        classList.push("er-button--text");
      }
      return classList.join(" ");
    }
  }
};
</script>

<style lang="scss">
.er-button {
  @include responsiveProperty(
  font-size,
  $app_font_size_1,
  $app_font_size_2,
  $app_font_size_3
  );
  height: 28px;
  & + & {
    margin-left: 10px;
  }
  &.el-button--default:not(.is-circle) {
    padding: 5px 15px;
  }
  &.el-button--mini:not(.is-circle) {
    @include responsiveProperty(
    font-size,
    $app_font_size_small,
    $app_font_size_1,
    $app_font_size_2
    );
    padding: 5px 10px;
  }
  &.el-button--text i {
    color: #000;
  }
  &.er-button--table_add i {
    color: #409eff;
  }
  &.er-button--icon-text.el-button--text {
    padding: unset;
  }
  &.el-button--text.is-disabled {
    color: #66b1ff;
  }
  &.er-button--icon-text span {
    // display: flex;
    // flex-direction: row;
    // align-items: center;
    // justify-items: center;

    & > img,
    & > i.material-icons-round,
    & > i.material-icons-outlined {
      @include icon-size(icon-font, prefix);
      margin-right: 5px;
    }
  }
  &.er-button--icon span {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;

    & > img,
    & > span.material-icons-round,
    & > span.material-icons-outlined {
      @include icon-size(icon-font, prefix);
    }
    & + span {
      margin-left: 5px;
    }
  }

  &.er-button--icon {
    &.er-button--edit {
      color: #00799f;
      &.is-disabled {
        color: #00799f99;
      }
    }
    &.er-button--delete {
      // background: #fff4f2;
      color: #fc196b;
      &.is-disabled {
        color: #fc196b99;
      }
    }
  }
  &.el-button--primary {
    background-color: #233a73;
    border-color: #233a73;
  }

  &.er-button--refresh.el-button--text.er-button--icon {
    &.no-padding {
      padding: 0;
    }
    span.material-icons-round {
      @include normal-text;
      color: black;
    }
  }

  &.el-button--default.is-disabled,
  &.el-button--default.is-disabled:active,
  &.el-button--default.is-disabled:focus,
  &.el-button--default.is-disabled:hover,
  &.el-button--default {
    &.er-button--save,
    &.er-button--calculate {
      color: #fff;
      background-color: #33a02c;
      border-color: #33a02c;
    }
    &.er-button--add,
    &.er-button--draw,
    &.er-button--circle_add {
      color: #fff;
      background-color: #155dbe;
      border-color: #155dbe;
    }
    &.er-button--editSolid {
      color: #fff;
      background-color: #155dbe;
      border-color: #155dbe;
    }
    &.er-button--print {
      color: #fff;
      background: #66d2ea;
      border-color: #66d2ea;
    }
    &.er-button--submit {
      color: #fff;
      background: #33a02c;
      border-color: #33a02c;
    }
    &.er-button--download {
      color: #fff;
      background: #155dbe;
      border-color: #155dbe;
    }
    &.er-button--email {
      color: #fff;
      background: #33a02c;
      border-color: #33a02c;
    }
    &.er-button--confirm {
      color: #fff;
      background: #f54e5e;
      border-color: #f54e5e;
    }
    &.er-button--warning {
      color: #fff;
      background: #e6a23c;
      border-color: #e6a23c;
    }
    &.er-button--deleteForever {
      color: #fff;
      background: #f54e5e;
      border-color: #f54e5e;
    }
    &.er-button--outlinePassword {
      color: #f54e5e;
      background: none;
      border-color: #f54e5e;
    }
    &.er-button--outlineEdit {
      color: #0e65e5;
      background: none;
      border-color: #0e65e5;
    }

    &.er-button--cancel {
      border: 1px solid #dcdfe6;
    }
  }

  &.er-button--primary {
    line-height: .5;
    border-radius: 3px;
  }
  &.is-disabled {
    opacity: .6;
  }
  &.icon-container {
    color: #000;
    font-size: 20px;
    // height: 24px;
    line-height: .6;
  }
  .btn-icon-svg {
    @include icon-size(svg);
  }
  .btn-icon-svg--prefix {
    @include icon-size(svg, prefix);
  }
  .btn-icon-svg--suffix {
    // @include icon-size(svg, prefix);
    margin-left: 5px;
  }
  .btn-icon-type-svg {
    img {
      @include icon-size(svg);
    }
    i {
      @include icon-size(icon-font);
    }
  }
}
</style>
