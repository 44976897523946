/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: dashboard.js
Description: This file contains all the vuex store functions used in dashboard page
*/
import createLogger from "vuex/dist/logger";
import Pond from "../../model/pond";

import PondsService from "../../services/PondsService";
import ShrimpGrowthService from "../../services/ShrimpGrowthService";
import WaterQualityService from "../../services/WaterQualityService";
import { cloneDeep } from "lodash";
import defaultDasboardFilters from "../../components/v2Dashboard/dashboard/utils/defaultDashboardFilters";

const INIT_STATE = {
  activePonds: [],
  inactivePonds: [],
  ponds: [],
  selectedPonds: [],
  feedConsumptionData: [],
  isFeedConsumptionFiltersUpdated: false,
  isPondPerformenceFiltersUpdated: false,
  isWeeklyGrowthFiltersUpdated: false,
  feedPeroid: "",
  pondPerformance: [],
  abwVsBiomassData: {},
  hourlyFeedGrowthStat: {},
  doSummary: {},
  tempSummary: {},
  weightGrowth: {},
  weightGrowthOfNWeeks: [],
  feedConsumptionGrowth: {},
  dashboardFilters: cloneDeep(defaultDasboardFilters)
};

export default {
  namespaced: true,
  plugins: [createLogger()],
  state: cloneDeep(INIT_STATE),
  getters: {
    getPonds: state => state.ponds,
    getActivePonds: state => state.activePonds,
    getInactivePonds: state => state.inactivePonds,
    selectedPonds: state => state.selectedPonds,
    getDashboardPonds: state => {
      return state.ponds;
    },
    getFeedConsumptionData: state => state.feedConsumptionData,
    getSelectedFeedPeriod: state => state.feedPeroid,
    getPondPerformance: state => state.pondPerformance,
    getAbwVsBiomassData: state => state.abwVsBiomassData,
    getAbwVsBIomassFilterData: state =>
      state.abwVsBiomassData.abw_range_values.reduce(function(acc, obj) {
        const key = obj.name;
        acc[key] = obj;
        return acc;
      }, {}),
    getHourlyFeedGrowthStat: state => state.hourlyFeedGrowthStat,
    getDOSummary: state => state.doSummary,
    getTempSummary: state => state.tempSummary,
    getWeightGrowth: state => state.weightGrowth,
    getweightGrowthOfNWeeks: state => state.weightGrowthOfNWeeks,
    getFeedConsumptionGrowth: state => state.feedConsumptionGrowth,
    getDashboardFilters: function(state) {
      return state.dashboardFilters;
    },
    getIsFeedConsumptionFiltersUpdated: state =>
      state.isFeedConsumptionFiltersUpdated,
    getIsPondPerformenceFiltersUpdated: state =>
      state.isPondPerformenceFiltersUpdated,
    getIsWeeklyGrowthFiltersUpdated: state => state.isWeeklyGrowthFiltersUpdated
  },
  mutations: {
    SELECT_PONDS: (state, ponds = []) => {
      state.selectedPonds = [...(ponds || [])];
      // state.selectedPonds = Object.assign({}, state.selectedPonds);
      if (ponds.length === 0) {
        state.scheduleFeed = [];
        state.doData = [];
        state.abwData = [];
      }
    },
    INIT_STORE_STATE: function(state) {
      Object.assign(state, cloneDeep(INIT_STATE));
    },
    SET_PONDS_DATA(state, pondsData) {
      if (pondsData != null) {
        const pondArr = [];
        pondsData.forEach(pond => {
          const pondObj = new Pond();
          pondObj.castToPondObj(pond);
          pondArr.push(pondObj);
        });
        state.ponds = pondArr;
      } else {
        state.ponds = null;
      }
    },
    SET_ACTIVE_PONDS_DATA(state, pondsData) {
      if (pondsData != null) {
        const pondArr = [];
        pondsData.forEach(pond => {
          const pondObj = new Pond();
          pondObj.castToPondObj(pond);
          pondArr.push(pondObj);
        });
        state.activePonds = pondArr;
      } else {
        state.activePonds = null;
      }
    },
    SET_INACTIVE_PONDS_DATA(state, pondsData) {
      if (pondsData != null) {
        const pondArr = [];
        pondsData.forEach(pond => {
          const pondObj = new Pond();
          pondObj.castToPondObj(pond);
          pondArr.push(pondObj);
        });
        state.inactivePonds = pondArr;
      } else {
        state.inactivePonds = null;
      }
    },
    SET_FEED_CONSUMPTION_DATA(state, feedData) {
      if (feedData != null) {
        state.feedConsumptionData = feedData;
      } else {
        state.feedConsumptionData = null;
      }
    },
    SET_FEED_PERIOD(state, selection) {
      state.feedPeroid = selection;
    },
    SET_POND_PERFORMANCE(state, performance) {
      state.pondPerformance = performance;
    },
    SET_ABW_VS_BIOMASS_DATA(state, data) {
      state.abwVsBiomassData = data;
    },
    SET_HOURLY_FEED_GROWTH_STAT(state, data) {
      state.hourlyFeedGrowthStat = data;
    },
    SET_DO_SUMMARY(state, data) {
      state.doSummary = data;
    },
    SET_TEMP_SUMMARY(state, data) {
      state.tempSummary = data;
    },
    SET_WEIGHT_GROWTH(state, data) {
      state.weightGrowth = data;
    },
    SET_WEIGHT_GROWTH_LAST_N_WEEKS(state, data) {
      state.weightGrowthOfNWeeks = data;
    },
    SET_FEED_CONSUMPTION_GROWTH(state, data) {
      state.feedConsumptionGrowth = data;
    },
    SET_DASHBOARD_FILTERS(state, { widgetNames, data }) {
      const tmpDashboardFilters = state.dashboardFilters;
      widgetNames.forEach(widgetName => {
        tmpDashboardFilters[widgetName] = data[widgetName];
      });
      state.dashboardFilters = tmpDashboardFilters;
    },
    SET_IS_FEED_CONSUMPTION_FILTERS_UPDT(state, data) {
      state.isFeedConsumptionFiltersUpdated = data;
    },
    SET_IS_POND_PERFORMANCE_FILTERS_UPDT(state, data) {
      state.isPondPerformenceFiltersUpdated = data;
    },
    SET_IS_WEEKLY_GROWTH_FILTERS_UPDT(state, data) {
      console.log("data----", data);
      state.isWeeklyGrowthFiltersUpdated = data;
    }
  },
  actions: {
    fetchAllPonds: async (context, params = {}) => {
      const response = await PondsService.fetchAllPonds(params);
      context.commit("SET_PONDS_DATA", response.data.ponds);
    },
    fetchActivePonds: (context, params = {}) => {
      return new Promise((resolve, reject) => {
        PondsService.fetchActivePonds(params)
          .then(response => {
            context.commit("SET_ACTIVE_PONDS_DATA", response.data.ponds);
            // context.commit("SET_MAP_PONDID_POND");
            resolve({
              message: response.data.message,
              totalPonds: response.data.total
            });
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    fetchInactivePonds: (context, params = {}) => {
      return new Promise((resolve, reject) => {
        PondsService.fetchInactivePonds(params)
          .then(response => {
            context.commit("SET_INACTIVE_PONDS_DATA", response.data.ponds);
            // context.commit("SET_MAP_PONDID_POND");
            resolve({
              message: response.data.message,
              totalPonds: response.data.total
            });
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    changeSelectedPonds: async (context, arrChangedSelectedPonds) => {
      context.commit("SELECT_PONDS", arrChangedSelectedPonds);
    },
    changeFeedPeriod: async (context, selection) => {
      context.commit("SET_FEED_PERIOD", selection);
    },
    fetchFeedConsumptionData: async (context, params = {}) => {
      const newParams = {
        ...params
        // type: context.state.feedPeroid
      };
      const response = await ShrimpGrowthService.fetchFeedConsumption(
        newParams
      );
      context.commit(
        "SET_FEED_CONSUMPTION_DATA",
        response.data.feed_consumption_summary
      );
    },
    fetchPondPerformance: async (context, params = {}) => {
      const response = await PondsService.fetchPondPerformance(params);
      context.commit("SET_POND_PERFORMANCE", response.data.ponds);
      context.commit("SET_IS_POND_PERFORMANCE_FILTERS_UPDT", false);
    },
    fetchAbwAndBiomass: async (context, params = {}) => {
      const response = await ShrimpGrowthService.fetchAbwAndBiomass(params);
      context.commit("SET_ABW_VS_BIOMASS_DATA", response.data.data);
    },
    fetchHourlyFeedGrowthStat: async (context, params = {}) => {
      const response = await ShrimpGrowthService.fetchHourlyFeedGrowthStat(
        params
      );
      context.commit("SET_HOURLY_FEED_GROWTH_STAT", response.data);
    },
    fetchDOSummary: async (context, params = {}) => {
      const response = await WaterQualityService.fetchDOSummary(params);
      context.commit("SET_DO_SUMMARY", response.data.pond_do_summary);
    },
    fetchTempSummary: async (context, params = {}) => {
      const response = await WaterQualityService.fetchTempSummary(params);
      context.commit(
        "SET_TEMP_SUMMARY",
        response.data.pond_temperature_summary
      );
    },
    fetchWeightGrowth: async (context, params = {}) => {
      const response = await ShrimpGrowthService.fetchWeightGrowth(params);
      context.commit("SET_WEIGHT_GROWTH", response.data.data);
    },
    fetchAverageWeightGrowthLastNWeeks: async (context, params = {}) => {
      const response = await ShrimpGrowthService.fetchAverageWeightGrowthLastNWeeks(
        params
      );

      context.commit(
        "SET_WEIGHT_GROWTH_LAST_N_WEEKS",
        response.data.weekly_growth
      );
      context.commit("SET_IS_WEEKLY_GROWTH_FILTERS_UPDT", false);
    },
    fetchFeedConsumptionGrowth: async (context, params = {}) => {
      const response = await ShrimpGrowthService.fetchFeedConsumptionGrowth(
        params
      );
      context.commit("SET_FEED_CONSUMPTION_GROWTH", response.data.data);
      context.commit("SET_IS_FEED_CONSUMPTION_FILTERS_UPDT", false);
    },
    savePondsToFilterInPondInfoTab: async (context, { card_type, input }) => {
      const cardToStoreKeys = {
        TEMPERATURE_CARD: {
          getter: "getTempSummary",
          IN: "with_threshold_ponds",
          OUT: "with_out_threshold_ponds"
        },
        DISSOLVED_OXYGEN_CARD: {
          getter: "getDOSummary",
          IN: "with_threshold_ponds",
          OUT: "with_out_threshold_ponds"
        }
      };
      const obj = cardToStoreKeys[card_type];

      const pondIds = context.getters[obj.getter][obj[input]] || [];

      await context.dispatch(
        "pondInfo/changeDashboardTabSelectedPondIds",
        { pondIds, tab: "ACTIVE" },
        {
          root: true
        }
      );
    },
    abwBiomassHyperlinkData: async (context, { name }) => {
      const abwvsBiomassObject = {
        "<=5": {
          name: "<=5",
          ponds: "ponds"
        },
        ">5-10": {
          name: ">5-10",
          ponds: "ponds"
        },
        ">10-20": {
          name: ">10-20",
          ponds: "ponds"
        },
        ">20-30": {
          name: ">20-30",
          ponds: "ponds"
        },
        ">30": {
          name: ">30",
          ponds: "ponds"
        }
      };
      const obj = abwvsBiomassObject[name];
      const ponds =
        context.getters.getAbwVsBIomassFilterData[obj.name].ponds || [];

      const pondIds = ponds.map(x => ({
        title: x.title,
        _id: x.pond_id
      }));

      await context.dispatch(
        "pondInfo/changeDashboardTabSelectedPondIds",
        { pondIds, tab: "ACTIVE" },

        {
          root: true
        }
      );
    },
    pondPerformanceHyperlinkData: async (context, { value }) => {
      const pondIds = value.map(x => ({
        title: x.title,
        _id: x.pond_id
      }));

      await context.dispatch(
        "pondInfo/changeDashboardTabSelectedPondIds",
        { pondIds, tab: "ACTIVE" },
        {
          root: true
        }
      );
    },
    averageWeeklyGrowthHyperLinkData: async (context, { ponds }) => {
      const pondIds = ponds.map(x => ({
        _id: x,
        title: "NO_titel"
      }));

      await context.dispatch(
        "pondInfo/changeDashboardTabSelectedPondIds",
        { pondIds, tab: "ACTIVE" },
        {
          root: true
        }
      );
    },
    pondCountCardHyperLink: async (context, { value }) => {
      await context.dispatch(
        "pondInfo/changeDashboardTabSelectedPondIds",
        { pondIds: [], tab: value },
        {
          root: true
        }
      );
    },
    fetchDashboardFilters: async (context, { widgetNames, params }) => {
      const response = await ShrimpGrowthService.fetchDashboardFilters(params);
      const mappedResponseData = await response.data.data.reduce(
        (acc, item) => {
          if (acc[item.widget_name] === undefined) {
            acc[item.widget_name] = {};
          }
          acc[item.widget_name][item.filter_name] = item.values[0];
          return acc;
        },
        {}
      );
      context.commit("SET_DASHBOARD_FILTERS", {
        widgetNames: Object.keys(mappedResponseData),
        data: mappedResponseData
      });
    },
    updateDashboardFilters: async (context, { widgetName, params }) => {
      const widgetNameToSetterMap = {
        AVERAGE_WEEKLY_GROWTH: "SET_IS_WEEKLY_GROWTH_FILTERS_UPDT",
        FEED_CONSUMPTION: "SET_IS_FEED_CONSUMPTION_FILTERS_UPDT",
        POND_PERFORMANCE: "SET_IS_POND_PERFORMANCE_FILTERS_UPDT"
      };
      const response = await ShrimpGrowthService.updateDashboardFilters(params);
      context.commit(widgetNameToSetterMap[widgetName], true);
      return response.data.data;
    }
  }
};
