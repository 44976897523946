/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: scheduleParamsCalculationUtils.js
Description: This file contains definitions of stateless helper functions used for PondMother schedule parameters calculation
*/
import {
  INIT_OCF_VALUE,
  MAX_OCF_VALUE,
  MIN_ON_TIME_SECS
} from "@/constants/schedule";

// const units = {
//   GRAM: 1,
//   KG: 1000,
//   SEC: 1,
//   MINUTE: 60,
//   HOUR: 3600, // 60 SECS
//   DAY: 86400
// };
export function timeStrHHmmValue(timeSlotStr) {
  // console.log(timeSlotStr);
  const [timeSlotHours, timeSlotMins] = timeSlotStr.split(":");
  const timeSlotValue = 60 * timeSlotHours + Number(timeSlotMins);
  return timeSlotValue;
}
// TT TOTAL TIME (MINS)
// TF TOTAL FEED (KGS)
// FG FEED GAP INTERVAL (MINS)
// OCF ONE CYCLE FEED (GMS)
// KGDT KG DISPENSED TIME (SEC)
// ONT ON TIME (MINS)
// OFT OFF TIME (MINS)
// STRT START TIME (MINS)
// STPT STOP TIME (MINS)

// no of cycles
export function calcTotalCycles({ TT, FG, TF, OCF }) {
  if (TT && FG) {
    return TT / FG;
  } else if (TF && OCF) {
    return TF / OCF;
  } else {
    console.error(
      "provide object similar to {TT:1,FG:1} or {OCF:1,TF:1} and every value should be > 0"
    );
  }
}

// OCF
export function calcOCF({ ONT, KGDT, TT, TF, FG }) {
  if (ONT && KGDT) {
    return ONT / (KGDT / 60);
  } else if (TT && TF && FG) {
    return ((TF * FG) / TT) * 1000;
  } else {
    console.error(
      "provide object similar to {ONT:1,KGDT:1} or {TT:1,FG:1,TF:1} and every value should be > 0"
    );
  }
}
// FEED GAP INTERVAL
export function calcFG({ ONT = 0, OFT = 0, TT = 0, TF = 0, OCF = 0 }) {
  if (ONT && OFT) {
    return ONT + OFT;
  } else if (TT && TF && OCF) {
    return TT * (OCF / (TF * 1000));
  } else {
    console.error(
      "provide object similar to {ONT:1,KGDT:1} or {TT:1,FG:1,TF:1} and every value should be > 0"
    );
  }
}

// TOTAL TIME
export function calcTT({ STPT, STRT, TF, FG, OCF }) {
  if (STRT && STPT) {
    return STPT - STRT;
  } else if (TF >= 0 && FG >= 0 && OCF >= 0) {
    return (TF * FG * 1000) / OCF;
  } else {
    console.error(
      "provide object similar to {ONT:1,KGDT:1} or {TT:1,FG:1,TF:1} and every value should be > 0"
    );
  }
}

// TOTAL FEED
export function calcTF({ TT, FG, OCF }) {
  if (!TT || !FG || !OCF) {
    console.error(
      "provide object similar to {TT:1,FG:1,OCF:1} and every value should be > 0"
    );
  }

  return (TT * OCF) / (FG * 1000);
}

// ONTIME
export function calcONT({ KGDT, OCF, FG, OFT }) {
  if (FG && typeof OFT !== "undefined") {
    return FG - OFT;
  } else if (KGDT && OCF) {
    return KGDT * OCF;
  } else {
    console.error(
      "provide object similar to {KGDT:1,OCF:1} OR {FG,OFT} and every value should be > 0"
    );
  }
}

// CALCULATE MAX OCF
export function calcMaxOCF({ KGDT, FG }) {
  return calcOCF({
    ONT: FG,
    KGDT: KGDT // here the kg dispensed feed time is missing
  });
}
// CALCULATE MAX OCF 2
export function calcMaxOCF2({ TF, FG }) {
  return calcOCF({
    FG: FG,
    TF: TF,
    TT: 1
  }); // TT should be MINIMUM 1 minutes so that the from and to time donot overlap
}
// CALCULATE MAX TOTAL FEED
export function calcMaxTFForADay({ STRTStr, FG, OCF }) {
  const STRT = timeStrHHmmValue(STRTStr);
  const STPT = 23 * 60 + 58;
  console.log(calcTT({ STPT, STRT }));
  return calcTF({ TT: calcTT({ STPT, STRT }), FG, OCF });
}
// CALCULATE MAX FG
export function calcMaxFGForADay({ STRTStr, TF, OCF }) {
  const STRT = timeStrHHmmValue(STRTStr);
  const STPT = 23 * 60 + 58;
  return calcFG({ TT: calcTT({ STPT, STRT }), TF, OCF });
}
export function calcPMScheduleEndTime({ STRTSecs, TF, FG, OCF }) {
  // since fg is in mins and TF is converted to grams in calcTT method
  let totalTimeMins = calcTT({ TF, FG, OCF });
  if (!totalTimeMins) {
    totalTimeMins = 1;
  }
  const startTime = STRTSecs / 60;
  const EndTime = startTime + totalTimeMins;
  let EndHours = Math.floor(EndTime / 60);
  let EndMins = Math.ceil(EndTime % 60);
  if (EndMins >= 60) {
    EndHours++;
    EndMins -= 60;
  }
  if (EndHours === 23 && EndMins > 60) {
    EndMins -= 1;
  }
  const changeXToXXFormat = value => (value > 9 ? value : "0" + value);
  const EndTimeStr =
    changeXToXXFormat(EndHours) + ":" + changeXToXXFormat(EndMins);
  return EndTimeStr;
}
export function calcPMScheduleStartTime({ STPTSecs, TF, FG, OCF }) {
  // since fg is in mins and TF is converted to grams in calcTT method
  const totalTimeMins = calcTT({ TF, FG, OCF });
  const EndTime = STPTSecs / 60;
  const startTime = EndTime - totalTimeMins;
  const StartHours = Math.floor(startTime / 60);
  const StartMins = Math.floor(startTime % 60);
  const changeXToXXFormat = value => (value > 9 ? value : "0" + value);
  const StartTimeStr =
    changeXToXXFormat(StartHours) + ":" + changeXToXXFormat(StartMins);
  return StartTimeStr;
}
export function suggestOCFForPm(totalTimeInMins, totalFeed) {
  let ocf = 200;
  for (ocf = INIT_OCF_VALUE; ocf < MAX_OCF_VALUE + 100; ocf += 50) {
    const feedGap = calcFG({
      TT: totalTimeInMins,
      TF: totalFeed,
      OCF: ocf
    });
    if (feedGap >= 1) {
      break;
    }
  }
  return ocf;
}
export function calcPondScheduleEndTime({ STRTSecs, TF, FG, OCF, pmsCount }) {
  // total time is in mins as FG is in mins
  let totalTime = calcTT({ TF: TF / pmsCount, FG, OCF });
  if (!totalTime) {
    totalTime = 1;
  }
  const startTime = STRTSecs / 60;
  const EndTime = startTime + totalTime;
  let EndHours = Math.floor(EndTime / 60);
  let EndMins = Math.ceil(EndTime % 60);
  if (EndMins >= 60) {
    EndHours++;
    EndMins -= 60;
  }
  const changeXToXXFormat = value => (value > 9 ? value : "0" + value);
  const EndTimeStr =
    changeXToXXFormat(EndHours) + ":" + changeXToXXFormat(EndMins);
  return EndTimeStr;
}
export function calcPondScheduleStartTime({ STPTSecs, TF, FG, OCF, pmsCount }) {
  // total time is in mins as FG is in mins
  const totalTime = calcTT({ TF: TF / pmsCount, FG, OCF });
  const EndTime = STPTSecs / 60;
  const startTime = EndTime - totalTime;
  const StartHours = Math.floor(startTime / 60);
  const StartMins = Math.floor(startTime % 60);
  const changeXToXXFormat = value => (value > 9 ? value : "0" + value);
  const StartTimeStr =
    changeXToXXFormat(StartHours) + ":" + changeXToXXFormat(StartMins);
  return StartTimeStr;
}
export function validateTSByChangingOCF(TT, TF, OCF, KGDT) {
  // no of cycles
  const noOfCycles = calcTotalCycles({ TF: +TF.toFixed(2), OCF: OCF * 0.001 });
  const onTimeMins = (OCF * 0.001 * KGDT) / 60;
  const fgMins = TT / noOfCycles;
  // console.assert(fgMins < 1);
  // const offTimeMins = fgMins - onTimeMins;
  // if (noOfCycles - 1 < -0.1) {
  //   return -1;
  // }
  if (noOfCycles < 1) {
    return -1;
  }
  if ((fgMins >= 1) && ((onTimeMins * 60) >= MIN_ON_TIME_SECS)) {
    return 0; // no need to change ocf
  }
  return 1;
}
export function calcFeasibleOCFForGivenTS(arrTF, arrTT, kgdt) {
  const totalTimeFunc = (TF, OCF) => {
    return (TF * 1000) / OCF;
  };
  const minOCF = 200;
  const maxOCF = 2500;
  // const step = 50;
  let maxTSSatified = 0;
  let maxTSSatifiedOCF = 2500;
  const TFToTT = arrTF.reduce((acc, curr, index) => {
    if (!acc[curr]) {
      acc[curr] = arrTT[index];
    } else if (acc[curr] > arrTT[index]) {
      acc[curr] = arrTT[index];
    }
    return acc;
  }, {});
  const tfKeys = Object.keys(TFToTT)
    .map(x => +x)
    .filter(x => x > 0)
    .sort((a, b) => a - b);
  const allTSCount = tfKeys.length;
  for (let ocf = minOCF; ocf < maxOCF + 50; ocf += 50) {
    let satisfiedTS = 0;
    for (let j = 0; j < tfKeys.length; j++) {
      if (
        totalTimeFunc(tfKeys[j], ocf) > TFToTT[tfKeys[j]] ||
        calcONT({ KGDT: kgdt, OCF: ocf / 1000 }) < MIN_ON_TIME_SECS
      ) {
        if (maxTSSatified < satisfiedTS) {
          maxTSSatified = satisfiedTS;
          maxTSSatifiedOCF = ocf;
        }
        break;
      }
      satisfiedTS++;
    }
    if (satisfiedTS === allTSCount) {
      return ocf;
    }
  }
  return maxTSSatifiedOCF;
}
// const calcPondOCF = function(1DT,ONT) {
//   // ocf calculation using totalFeed,FeedGapInterval

// };
export function Test() {
  //   console.assert( === 474);
}

export default {
  timeStrHHmmValue,
  calcOCF,
  calcFG,
  calcTT,
  calcTF,
  calcONT,
  calcMaxOCF,
  calcMaxTFForADay,
  calcMaxFGForADay,
  calcPMScheduleEndTime,
  calcPMScheduleStartTime,
  calcPondScheduleEndTime,
  calcPondScheduleStartTime,
  calcTotalCycles,
  validateTSByChangingOCF,
  calcFeasibleOCFForGivenTS,
  suggestOCFForPm,
  Test
};
