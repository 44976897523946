<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: noPondsView.vue
Description:This file gives the brief information how to use the application.
-->
<template>
  <layout-pondlogs header="none" class="no-pond">
    <el-card slot="layout-pondlogs-scrollable-main" class="instr_cont">
      <h3>{{ $t("Comn_dashboard_take_some_steps_msg") }}</h3>
      <h4>{{ $t("Comn_dashboard_help_msg") }}</h4>
      <el-row v-for="item in displayData" :key="item.header" class="outer-row">
        <el-col>
          <el-link :underline="false" :href="item.url">
            <el-card shadow="never">
              <el-row>
                <h2>{{ item.header }}</h2>
              </el-row>
              <el-row>
                <h3>{{ item.description }}</h3>
              </el-row>
            </el-card>
          </el-link>
        </el-col>
      </el-row>
    </el-card>
  </layout-pondlogs>
</template>

<script>
export default {
  computed: {
    displayData: function () {
      return {
        create_pond: {
          header: this.$t("Comn_creating_ur_pond"),
          description: this.$t("Comn_ovrview_map_srch_msg"),
          url: "/user/map-view"
        },
        init_pond_settings: {
          header: this.$t("Comn_initialize_stock_date"),
          description: this.$t("Comn_manage_settings_ponds_msg"),
          url: "/user/settings?tab=pondTab"
        },
        add_resources: {
          header: this.$t("Resource_add_reso"),
          description: this.$t("Comn_dash_manage_resource"),
          url: "/user/resources"
        },
        stock_feed: {
          header: this.$t("Stock_add_stock"),
          description: this.$t("Comn_insert_feed_medicines_mineral_stock"),
          url: "/user/stock"
        },
        user_management: {
          header: this.$t("Usrs_add_sub_usr"),
          description: this.$t("Comn_dash_manage_sett_usrs"),
          url: "/user/settings?tab=userAccessManagement"
        }
      };
    }
  }
};
</script>

<style lang="scss">
.no-pond {
  .el-row.outer-row:first-of-type {
    margin-top: 20px;
  }
  .el-row.outer-row {
    margin-bottom: 10px;
  }
  .el-link {
    width: 100%;
  }
  .el-link--inner {
    width: 90%;
  }
  .el-link.el-link--default {
    height: max-content;
  }
}
</style>
