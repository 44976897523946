export const RANGE_TYPES = {
  IN_BETWEEN: "IN_BETWEEN",
  LESS_THAN_OR_EQUALS: "LESS_THAN_OR_EQUALS",
  GREATER_THAN: "GREATER_THAN"
};

export const getRangeValue = function(
  start,
  end,
  isEndValueInfinite,
  isFirstRange
) {
  let rangeType = RANGE_TYPES.IN_BETWEEN;
  if (isFirstRange) {
    rangeType = RANGE_TYPES.LESS_THAN_OR_EQUALS;
  }
  if (isEndValueInfinite) {
    rangeType = RANGE_TYPES.GREATER_THAN;
  }
  return {
    start: start,
    end: end,
    is_end_value_infinite: isEndValueInfinite,
    range_type: rangeType,
    update_end_on_null: 0
  };
};

export const getRanges = function(length, initStart, initEnd, incrementValue) {
  return new Array(length).fill(0).reduce((acc, curr, index) => {
    if (index === 0) {
      acc.push(getRangeValue(initStart, initEnd, false, true));
      return acc;
    }
    if (index === length - 1) {
      acc.push(getRangeValue(acc[acc.length - 1].end, null, true, false));
      return acc;
    }
    acc.push(
      getRangeValue(
        acc[acc.length - 1].end,
        acc[acc.length - 1].end + incrementValue,
        false,
        false
      )
    );
    return acc;
  }, []);
};

export const getNameForRange = function(range) {
  if (range.range_type === RANGE_TYPES.LESS_THAN_OR_EQUALS) {
    return `≤${range.end}`;
  }

  if (range.range_type === RANGE_TYPES.GREATER_THAN) {
    return `>${range.start}`;
  }

  return `${range.start}-${range.end}`;
};

export default {
  getRanges,
  getRangeValue,
  getNameForRange,
  RANGE_TYPES
};
